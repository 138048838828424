import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gis from "/src/components/img/gis"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Směrovka na mapě" />
    <h1>Směrovka na mapě</h1>

    <p><strong>Směrovka</strong>&nbsp;na mapě je grafick&yacute;m vyj&aacute;dřen&iacute;m orientace mapy ku světov&yacute;m stran&aacute;m. Jde o&nbsp;<Link to="/gis/mapova-kompozice/">nadstavbov&yacute; kompozičn&iacute; prvek</Link>&nbsp;(grafick&aacute; margin&aacute;lie), přesto by měla b&yacute;t až na v&yacute;jimky um&iacute;stěna na mapě.</p>
    <hr /><h2>Kdy&nbsp;neumisťovat směrovku na&nbsp;mapu</h2>
    <p>Př&iacute;pady, kdy&nbsp;<strong>směrovka nem&aacute; b&yacute;t vložena</strong>&nbsp;do mapov&eacute; kompozice, jsou např&iacute;klad:</p>
    <ul>
    <li>na mapě je př&iacute;tomna zeměpisn&aacute; s&iacute;ť,</li>
    <li>jedn&aacute; se o &uacute;zem&iacute; orientovan&eacute; na sever (i souřadn&yacute; syst&eacute;m tak mus&iacute; b&yacute;t orientov&aacute;n),</li>
    <li>mapa je souč&aacute;st&iacute; souboru map (cel&eacute; mapov&eacute; d&iacute;lo orientov&aacute;no určit&yacute;m směrem).</li>
    </ul>
    <hr /><h2>Vhodn&aacute; podoba mapov&eacute; směrovky</h2>
    <p>Vhodnou podobou je jednoduch&aacute; magnetick&aacute; střelka či &scaron;ipka směřuj&iacute;c&iacute; k severu. Pokud u směrovky popisujeme světov&eacute; strany, slovy či p&iacute;smeny, mus&iacute; b&yacute;t naps&aacute;ny v jazyce, v kter&eacute;m byla tvořena mapa. Nen&iacute; tak př&iacute;pustn&eacute;, aby na česk&eacute; mapě byla směrovka se severem popsan&yacute;m jako &bdquo;N&ldquo;, popř&iacute;padě &bdquo;North&ldquo;. Při v&yacute;běru směrovky je vhodn&eacute; upřednostnit co nejjednodu&scaron;&scaron;&iacute; proveden&iacute;, kter&eacute; nebude zbytečně zatěžovat mapovou kompozici a odv&aacute;dět pozornost od mapov&eacute;ho pole.</p>
    <hr /><h2>Směrovka v&nbsp;česk&eacute;m prostřed&iacute;</h2>
    <p>Pro česk&eacute; podm&iacute;nky plat&iacute; n&aacute;sleduj&iacute;c&iacute; pomůcka. Při tvorbě mapy v souřadnicov&eacute;m syst&eacute;mu<strong>&nbsp;S-JTSK (Křov&aacute;k)</strong>, kter&yacute; nen&iacute; na &uacute;zem&iacute; ČR orientov&aacute;n k severu, je<strong>&nbsp;nutn&eacute; směrovku vkl&aacute;dat</strong>.</p>
    <p>U druh&eacute;ho nejpouž&iacute;vaněj&scaron;&iacute;ho syst&eacute;mu&nbsp;<strong>WGS84 33 N</strong>, kter&yacute; je naopak k severu orientov&aacute;n, je&nbsp;<strong>použit&iacute; směrovky zbytečn&eacute;</strong>.</p>

    <hr />
    <ImgCon><Gis /><div>
    <h2>Studijn&iacute; materi&aacute;ly GIS, geoinformatika a&nbsp;kartografie</h2>
    <p>T&eacute;ma <strong>Směrovka na mapě</strong> je souč&aacute;st&iacute; <Link to="/gis/">studijn&iacute;ch materi&aacute;lů GIS, geoinformatika a kartografie</Link> vytvořených zejména na podkladě předmětů vyučovaných na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat, případně na téma předch&aacute;zej&iacute;c&iacute; či n&aacute;sleduj&iacute;c&iacute;.</p>
    <BtnWrap>
    <Link to="/gis/"><button>Rozcestník GIS</button></Link>
    <Link to="/gis/mapova-kompozice/nazev-mapy/"><button className="inv">&larr; Název mapy</button></Link>
    <Link to="/gis/"><button className="inv">Nedokončeno :c &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
